import { FC } from "react";
import "./timeLoader.css";

export const TimeLoader: FC = () => {
  return (
    <>
      <div className="timeLoaderContainer">
        <span className="timeLoader"></span>
      </div>
    </>
  );
};
