import React from "react";

export const Loader = () => (
  <div className={`lds-ellipsis`}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
